import { Box } from "atomic-layout"
import { Link } from "gatsby"
import React from "react"
import { Content, Heading2, Layout, Button, SEO, FeatureBox } from "../components"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Center = styled.div`
  text-align: center;
  padding-bottom: 1rem;
`

const imgDropper = () => (
  <StaticImage
    src="../images/dropper.png"
    alt="Mouse Pointer hovering over logo showing how Eye Dropper can find display color under cursor."
    imgClassName="rounded"
    quality={100}
  />
)

const imgPicker = () => (
  <StaticImage
    src="../images/picker.png"
    alt="Color Picker focused on green color showing how previous and current color is dispplayed in extension."
    imgClassName="rounded"
    quality={100}
  />
)
const imgHistory = () => (
  <StaticImage
    src="../images/history.png"
    alt="Small rectangles with different background colors representing history of picked colors."
    imgClassName="rounded"
    quality={100}
  />
)
const Home = () => {
  const About = <>Eye Dropper allows you to easily pick colors from various sources.</>

  return (
    <Layout about={About}>
      <SEO />
      <Content>
        <Box
          flex
          gap="2rem"
          flexDirection="column"
          flexDirectionM="column"
          flexDirectionL="row"
          justifyContent="space-around"
        >
          <FeatureBox title="Pick color from webpage" img={imgDropper}>
            You can pick any color on current webpage and save it to your clipboard and color
            history.
          </FeatureBox>
          <FeatureBox title="Pick from color picker" img={imgPicker}>
            Eye Dropper has integrated color picker. You can mix or find the colors as you need.
          </FeatureBox>
          <FeatureBox title="Pick color from history" img={imgHistory}>
            Every picked color is stored into actual color palette where you can find it in later.
          </FeatureBox>
        </Box>

        <Heading2>Open Source without any tracking</Heading2>
        <dl>
          <dt>Open</dt>
          <dd>
            <a href="https://github.com/kepi/chromeEyeDropper">Free and Open Source</a> browser
            extension.
          </dd>

          <dt>Safe</dt>
          <dd>
            <Link to="/privacy">Doesn't collect</Link> your data. Doesn't track you.
          </dd>

          <dt>Trusted</dt>
          <dd>1,000,000+ users.</dd>

          <dt>Popular</dt>
          <dd>
            Close to 2,000 positive reviews at{" "}
            <a href="https://chrome.google.com/webstore/detail/eye-dropper/hmdcmlfkchdmnmnmheododdhjedfccka">
              Chrome Web Store
            </a>
            .
          </dd>

          <dt>Stable</dt>
          <dd> Developed since 2010.</dd>
        </dl>
        <Center>
          <Button
            logoName="chrome"
            to="https://chrome.google.com/extensions/detail/hmdcmlfkchdmnmnmheododdhjedfccka"
          >
            Get Eye Dropper for <strong>Chrome</strong>
          </Button>
        </Center>
      </Content>
    </Layout>
  )
}

export default Home
